











































import { Component, Vue } from 'vue-property-decorator'
import Modal from '@/components/Modal/Modal.vue'
import CurrentFilters from './CurrentFilters.vue'
import { SearchSongService } from '@/services/SearchSongService';
import { cloneDeep } from 'lodash';

@Component({
  components:{ 
    Modal, 
    CurrentFilters,
  }
})
export default class RefineFilterModal extends Vue {
  store = SearchSongService;
  
  get search(){ 
    return this.$route.query.search as string;
  }

  get keysOfAvailableFilters() { 
    return Object.keys(this.store.availableFilters);
  }

  show(){ 
    (this.$refs.modal as Modal).show();
  }

  applyFilter(filter: { id: string; label: string; count: number }, categoryKey: string){
    const query = cloneDeep(this.$route.query);
    if (!Object.keys(query).includes(categoryKey)){ 
      query[categoryKey] = [];
    }else if (typeof query[categoryKey] === 'string'){
      query[categoryKey] = [query[categoryKey] as string];
    }
    if (categoryKey === 'adminIds'){
      (query[categoryKey] as string[]).push(filter.id);
    }else{ 
      (query[categoryKey] as string[]).push(filter.label);
    }
    this.$router.push({ query: query});
    (this.$refs.modal as Modal).hide();
  }
}
