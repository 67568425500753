































import { Component, Vue } from 'vue-property-decorator'
import Modal from '@/components/Modal/Modal.vue';
import { BusyService } from '@/services/BusyService';
import ChordProPreview from '@/components/ChordProEditor/ChordProPreview.vue';
import { TerritoryService } from '@/services/TerritoryService';
import { ApiService } from '@/services/ApiService';
import { ChordsData } from '@/models/ChordsData';
import { GTMService } from '@/services/GTMService';

@Component({
  components:{
    Modal,
    ChordProPreview
  }
})
export default class ChordsModal extends Vue {
  songNumber ='';
  songTitle ='';
  chords = '';
  authors = '';
  canViewChords = false;

  async init(songNumber: string, songTitle: string, authors: string){
    this.songNumber = songNumber;
    this.songTitle = songTitle;
    this.authors = authors;
    this.canViewChords = false;
    BusyService.showBusy();
    GTMService.pushCustomEvent({ event: 'showChordModal' })
    const response =  await ApiService.post<ChordsData>('/api/GetChords', {songNumber: parseInt(songNumber), territoryId: parseInt(TerritoryService.territoryId) })
    this.canViewChords = response.canViewChords;
    this.chords = response.chords;
    BusyService.hideBusy();
    (this.$refs.modal as Modal).show();
  }
}
