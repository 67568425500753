









import { bus, i18n } from '@/main';
import { Component, Vue, Watch } from 'vue-property-decorator'
import SongResults from './SongResults.vue'
import { SearchSongService } from '@/services/SearchSongService';
import { DynamicTranslationService } from '@/services/DynamicTranslationService';

@Component({
  metaInfo: () => ({
    title: DynamicTranslationService.searchAllSongsTitle
  }), 
  components:{ 
    SongResults
  }
})
export default class SearchAllSongs extends Vue {
  store = SearchSongService;
  get searchResults(){ 
    return SearchSongService.searchResults;
  }

  mounted() { 
    SearchSongService.getQueryParams();
    SearchSongService.performSearch();
    bus.$on('territoryUpdated', this.onTerritoryUpdate);
  }
  
  beforeDestroy() {
    bus.$off('territoryUpdated');
  }

  onTerritoryUpdate(){ 
    SearchSongService.onSetTerritory();
  }

  @Watch('$route')
  async performSearch(){ 
    SearchSongService.getQueryParams();
    SearchSongService.performSearch();
  }
}
